<template>
  <Dialog width-class="md:max-w-[510px]">
    <div class="px-md pb-md flex flex-col max-h-[92vh]">
      <div v-if="activate" class="flex justify-center">
        <img
          src="@/assets/images/minilu/collectiveInvoiceActivate.svg"
          class="relative -top-md"
        />
      </div>
      <div v-else class="flex justify-center">
        <img
          src="@/assets/images/minilu/collectiveInvoiceDeactivate.svg"
          class="relative -top-md"
        />
      </div>

      <div class="overflow-auto h-fit">
        <div v-if="error" class="mb-md">
          <UnknownError />
        </div>
        <template v-if="activate">
          <div class="text-center mb-md">
            <div>
              {{ t('shop.dialog.collectiveInvoice.activate.text1') }}
            </div>

            <i18n-t
              keypath="shop.dialog.collectiveInvoice.activate.text2"
              class="text-center mt-sm mb-xs"
            >
              <template #FAQ_LINK>
                <NuxtLink to="/haeufige-fragen">
                  <span class="font-bold text-primary-base">{{
                    t('shop.dialog.collectiveInvoice.activate.faqLinkText')
                  }}</span>
                </NuxtLink>
              </template>
            </i18n-t>
          </div>
          <ButtonBasic
            classes="w-full px-sm md:px-0"
            :label="t('shop.dialog.collectiveInvoice.activate.buttonText')"
            :disabled="isLoading"
            full-width
            @click="dialog.close()"
          ></ButtonBasic>
        </template>
        <template v-else>
          <div class="text-center">
            {{ t('shop.dialog.collectiveInvoice.deactivate.text1') }}
          </div>
          <div class="text-center mb-md">
            {{ t('shop.dialog.collectiveInvoice.deactivate.text2') }}
          </div>
          <ButtonBasic
            classes="w-full px-sm md:px-0"
            :label="t('shop.dialog.collectiveInvoice.deactivate.buttonText')"
            :disabled="isLoading"
            full-width
            @click="deactivateCollectiveInvoice"
          ></ButtonBasic>
          <div
            class="flex items-center justify-center w-full gap-2 cursor-pointer text-primary-base mt-md"
            @click="dialog.cancel()"
          >
            <span class="anim__underline-link anim-link">
              {{ t('shop.dialog.collectiveInvoice.deactivate.cancel') }}
            </span>
          </div>
        </template>
      </div>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import UnknownError from '~/components/formFields/errors/unknown/unknownError.vue';
import Dialog from '@/components/dialog/components/dialogFrame/empty/dialogFrame.vue';
import ButtonBasic from '~~/src/components/components/core/basic-button/basic-button.vue';
import useCollectiveInvoiceDialog from '~/composables/dialogs/useCollectiveInvoiceDialog';

const { t } = useTrans();

const dialog = useCollectiveInvoiceDialog();
const activate = dialog.getData().activate;

const error = ref(false);
const isLoading = ref(false);

async function deactivateCollectiveInvoice() {
  isLoading.value = true;
  await dialog.changeCollectiveInvoice(false);
  isLoading.value = false;

  dialog.close();
}
</script>
